@import 'src/sass/main.scss';

.ItemContainer {
  width: auto;
  position: relative;
  box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  margin: 1rem 1.5rem;
  box-sizing: border-box;
  border-radius: $border-radius;
  background-color: $color-white;
}

.ItemContainer p {
  padding: 0.25rem 0;
  margin: 0;
}

.ItemContainer:hover {
  cursor: pointer;
  background-color: $color-accent-light;
  box-shadow: 0 1rem 2rem rgba($color-black, 0.2);
  transform: translateY(-2px);
}

.ItemContainer:active {
  box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  transform: translateY(-1px);
}

.Description {
  padding: 0.5rem 1rem;
}

.IngredientContainer {
  text-align: left;
  padding: 0.25rem 1rem;
}

.Ingredient {
  margin: 0.25rem 0.5rem;
  width: auto;
  display: inline-block;
  padding: 0 1rem;
  box-sizing: border-box;
  border: 1px solid $color-grey-light;
  border-radius: 500px;
  font-size: 0.8rem;
}
