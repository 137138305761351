.Lander {
  padding: 80px 0;
  text-align: center;
}

.BottomVersion {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
