@import 'src/sass/main.scss';

.Bottombar {
  border-top: 3px solid $color-grey-light;
  position: sticky;
  height: fit-content;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 10px 0;
  background: white;

  button {
    float: right;
    padding: 0.5rem 1rem;
    margin: auto;
    border: none;
    color: $color-tertiary-dark;
  }
}
