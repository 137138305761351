.ActionWrapper {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.CheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 10px;
}

.Table {
  font-size: 12px !important;
}

.DataItem {
  padding: 9px 30px !important;
}

.IdItem {
  padding: 9px 8px !important;
}
.ActionIcon:hover {
  color: rgb(10, 188, 201);
  cursor: pointer;
}

.NewRow {
  background: #699cff21;
}

.CanceledRow {
  background: #f1626038;
}

.ActionIcon {
  font-size: 17px !important;
}

.Switch {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.userIcon {
  min-width: 25px;
  min-height: 25px;
  margin: 0 5px;
  background-color: #cccccc;
  mask: url("../../../../img/user.svg") no-repeat center;
  -webkit-mask: url("../../../../img/user.svg") no-repeat center;
}

.icon {
  display: flex;
  align-items: center;
  > svg {
    width: 25px;
    height: 25px;
    margin: 0 5px;
  }
}

.active {
  color: #46b8da;
  > svg {
    fill: #46b8da;
  }
}

.Container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 5fr 4fr 3fr 2fr;
  justify-content: space-between;
  grid-gap: 10px;
}

.DateWrapper {
  display: flex;
  margin: 40px 20px;
  justify-content: center;
  > p {
    margin-right: 10px;
  }
}

.OrderHistoryContainer {
  width: 100%;

  h4 {
    margin-top: 0 !important;
  }
}

.FactoryBackground {
  background: #4bd1d6 !important;
  color: white !important;
}

.RestaurantBackground {
  background: #f77347 !important;
  color: white !important;
}

.ActionBackendWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pastSearchButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
}
