@import "src/sass/main.scss";

.Wrapper {
  margin-bottom: 10px;
}

.FormGroup {
  position: relative;
}

.Input {
  width: 100%;
  background-color: $color-primary;
  padding: 0 0 0 35px;
  border: none !important;
  border-radius: $border-radius;
  margin: 0 !important;
}

.Icon {
  position: absolute;
  left: 10px;
  top: 8px;
}

.Error {
  box-shadow: inset 0 0 5px #ff8f8f;
}
