body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
} */

/* React dates highlighed color */
.CalendarDay__highlighted_calendar {
  background: rgb(215, 43, 43) !important;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}

input[type='file'] {
  width: 100%;
}
.rdrDefinedRangesWrapper {
  display: none;
}
.rdrDateDisplayWrapper {
  display: none;
}
.react-daterange-picker__wrapper {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-radius: 0.28571429rem !important;
}
.react-date-picker__inputGroup__divider {
}
.react-date-picker__inputGroup__divider::after {
}
.react-daterange-picker__inputGroup__leadingZero {
  font-size: 1em;
}
.react-daterange-picker__clear-button {
  display: none;
}

.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: 0;
  padding-left: 0 !important;
}
