@import 'src/sass/main.scss';

.HistoryCardContainer {
  margin: 1rem 0;
  padding: 1rem 2rem;
  background: $color-primary;
  border-radius: $border-radius;

  ol {
    list-style-position: inside;
  }
}

.Info {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}
