@import 'src/sass/main.scss';
.Dropdown {
  text-indent: 30px;
  border: none;
  box-shadow: 0 0.1rem 0.2rem rgba($color-black, 0.2);

  &:hover {
    cursor: pointer !important;
  }
}

.FormGroup {
  position: relative;
}

.Icon {
  position: absolute;
  left: 10px;
  top: 8px;
}

.Error {
  box-shadow: inset 0 0 5px #ff8f8f;
  -webkit-box-shadow: inset 0 0 5px #ff8f8f;
  -moz-box-shadow: inset 0 0 5px #ff8f8f;
}
