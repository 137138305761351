.Container {
  position: relative;
}

.Row {
  width: 90%;
  height: 70px;
  display: flex;
  font-size: 18px;
  border-top: 2px dashed #d2d2d2;
  padding: 10px 0;
  position: relative;
  cursor: pointer;
  margin: 0 auto;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s ease-in-out;
  }
}

.ActiveRow {
  background: rgb(89, 121, 249);
}

.Empty {
  background-color: #78df78;
  border-right: 1px solid lightgray;
}

.Reserved {
  background-color: #90d2fa;
  border-right: 1px solid lightgray;
}

.Taken {
  background-color: #fc5555;
}

.ShopOrder {
  background-color: #f98700;
}

.Time {
  position: absolute;
  font-size: 16px;
  top: -18px;
  left: -25px;
}

.Cross {
  z-index: 2;
  top: -18px;
  right: 25px;
  font-size: 16px;
  position: absolute;
  color: red;
  cursor: pointer;
}
