.Breadcrumb {
  margin-top: 40px !important;
  background-color: white;
  text-decoration: none !important;
  padding-left: 0 !important;
  margin-bottom: 30px !important;
  a {
    text-decoration: none !important;
  }
  a {
    color: #d1d1d1 !important;
  }
  a:last-child {
    color: black !important;
  }
}
