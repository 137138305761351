// Fix problem with the 0px height of some divs
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// Progress bar mockup
@mixin progressPercentage($progress) {
  div.progressBar div {
    width: $progress;
    background: rgb(100% - $progress, $progress, 50%);
  }

  div.progressBar div:after {
    content: "#{round($progress)}"
  }
}

// MEDIA QUERY MANAGER
/*
0-600px:      phone
600-900px:    tablet vertical
900-1200px:   tablet horizontal
1200-1800px:  desktop - default in this project
1800px+:      big desktop

$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

em is the best unit for media queries
1em is 16px

Workflow for desktop first designs to provide responsibility:
Base -> typography -> general leyout and grid -> page layou -> components
*/
@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media (max-width: 37.5em) {
      // @content allows to pass a block of code to the mixin
      @content
    }

    ;
  }

  @if $breakpoint==tab-port {
    @media (max-width: 56.25em) {
      // @content allows to pass a block of code to the mixin
      @content
    }

    ;
  }

  @if $breakpoint==tab-land {
    @media (max-width: 75em) {
      // @content allows to pass a block of code to the mixin
      @content
    }

    ;
  }

  @if $breakpoint==big-desktop {
    @media (min-width: 112.5em) {
      // @content allows to pass a block of code to the mixin
      @content
    }

    ;
  }
}