/* wszystkie kwestie dotyczące fontów muszą się znaleźć w body selector - optymalizacja związana z inheritance
*/
body {
  font-family: 'Lato', sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.7;
  color: $color-dark-text;
}

.HeadingPrimary {
  backface-visibility: hidden;
  margin-bottom: 6rem;
  margin-top: 30vh;

  @include respond(tab-land) {
    margin-top: 25vh;
    margin-bottom: 2rem;
  }

  @include respond(tab-port) {
    margin-top: 30vh;
    margin-bottom: 6rem;
  }

  &--main {
    display: block;
    /*block occupies full line width and provides line brakes*/
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: 1.95rem;
    text-transform: uppercase;

    animation-name: moveInLeft;
    animation-duration: 1s;

    @include respond(tab-land) {
      padding-left: 2rem;
      font-size: 4.2rem;
      letter-spacing: 1.1rem;
    }

    @include respond(tab-port) {
      margin-left: -10rem;
    }

    @include respond(phone) {
      letter-spacing: 1rem;
      font-size: 4rem;
      text-align: center;
      margin-left: 0;
      color: $color-grey-light-1;
      padding-left: 0rem;
    }

    /* animation-iteration-count: 3; */
  }

  &--sub {
    display: block;
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 1.2rem;
    text-transform: uppercase;

    animation: moveInRight 1s ease-out;

    @include respond(tab-land) {
      padding-left: 2rem;
      font-size: 1.6rem;
      letter-spacing: .9rem;
    }

    @include respond(tab-port) {
      margin-left: -10rem;
    }

    @include respond(phone) {
      padding-left: 0rem;
      letter-spacing: 1rem;
      text-align: center;
      margin-left: 0;
      color: $color-grey-light;
    }
  }

  &--explain {
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    padding-right: 4rem;
    color: $color-medium-text;
    animation: moveInRight 1s ease-out;

    @include respond(tab-land) {
      padding-left: 2rem;
      font-size: 1.6rem;
    }

    @include respond(tab-port) {
      margin-left: -10rem;
      width: 100%;
    }

    @include respond(phone) {
      padding-left: 0rem;
      margin-left: 0rem;
      text-align: center;
      width: 100%;
      color: $color-grey-light;
      padding-right: 0rem;
    }
  }
}

.HeadingSecondary {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  text-transform: uppercase;

  animation-name: moveInLeft;
  animation-duration: 1s;
  transition: all .2s;

  @include respond(tab-port) {
    font-size: 2.5rem;
  }

  @include respond(phone) {
    font-size: 2rem;
  }

  &__contact {
    background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  &__light-color {
    background-image: linear-gradient(to right, $color-white, $color-grey-light);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

.HeadingTertiary {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
}

.HeadingDescriptive {
  font-size: $default-font-size;
  text-transform: uppercase;
  font-weight: 700;
  // Gradient text code below
  display: inline-block;
  background-image: linear-gradient(to right, $color-primary, $color-primary-dark);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s;
}

.Paragraph {
  font-size: $default-font-size;
  color: $color-medium-text;

  &__light-text {
    color: $color-light-text;
  }

  &__cards {
    font-size: 1.4rem;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.call-out {
  display: block;
  font-size: 5rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  background-image: linear-gradient(to right, $color-secondary-light, $color-secondary-dark);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}