.Container {
  height: 120px;
  border-top: 2px solid #e6e6e6;
  background: white;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
}

.ButtonContainer {
  display: flex;
}

.Counter {
  display: flex;
  align-items: center;
  > h3 {
    margin: 0 10px 0 0 !important;
  }
}
