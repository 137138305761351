.Layout {
  width: 100vw;
  overflow: hidden;
  height: calc(100vh - 70px);
	display: flex;
}
.Table {
  overflow-y: auto;
  height: fit-content;
  position: absolute;
  top: 90px;
  right: 0px;
  left: 0px;
  bottom: 0;
  font-size: 14px;
}

.Table th {
  position: sticky;
  top: 0;
}

.Table > table {
  border-collapse: collapse;
  width: 100%;
}

.Table > table > thead > tr > th {
  padding: 8px 16px;
}

.Table > table > tbody > tr > td {
  padding: 8px 16px;
}

.Table > table > thead > tr > th {
  background: #eee;
}

.PrintContainer {
  display: flex;
  justify-content: space-between;
}
