@import "src/sass/main.scss";

.OrderSection {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden auto;
}

.OrderWrapper {
  position: relative;
  min-height: 200px;
  height: calc(100% - 280px - 140px - 50px);
}

.EmptyBasket {
  animation: slideDown 0.5s ease-in;
  animation-iteration-count: 1;
  justify-content: center;
  position: relative;
  height: 100%;
  display: flex;
  text-align: center;
  flex-flow: column;

  svg {
    color: gray;
    margin: auto;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// .Header {
//   display: flex;
//   flex: 1;
//   justify-content: space-between;
//   flex-direction: row;
//   padding: 10px 0;
//   .price {
//     font-weight: bold;
//   }
//   h3 {
//     margin: 0;
//   }
// }

.Header {
  display: grid;
  grid-template-columns: 25% auto 35%;
  text-align: center;
  padding: 10px 0;
  .price {
    font-weight: bold;
    text-align: right;
  }
  h3 {
    margin: 0;
    text-align: left;
  }
  h3:last-child {
    text-align: right;
    padding-right: 12px;
  }
}

.SwitchContainer {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto 25%;
  column-gap: 10px;
  padding: 10px 0;
  justify-content: flex-start;

  p {
    font-size: 20px;
    // margin: auto 10px;

    // svg {
    //   margin-right: 5px;
    // }
  }

  .Wrapper {
    padding: 0;
  }
}

// #test {
//   padding: 0 !important;
// }
