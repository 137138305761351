@import 'src/sass/main.scss';

.MenuList {
  padding-top: 10px;
  overflow-y: auto !important;
  background-color: $color-primary;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
}

.sec {
  height: 900px;
  width: 100%;
}
