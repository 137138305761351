.Section {
  flex: 1;
  overflow: auto;
  position: relative;
  display: flex;
  align-items: stretch;
}

.Container {
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
  position: relative;
}