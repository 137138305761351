.ModalWrapper > div {
  width: 80vw !important;
}
.SwapWrapper {
  margin:24px 0;
  h1 {
    font-size: 16px;
    font-weight: bold;
  }
}
.DropDownsWrapper {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  gap:16px;
  div {
    flex:1;
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
  }
}
