.BtnContainer {
  display: flex;
  flex-direction: row-reverse;
}
.Btn {
  margin: 40px 10px !important;
  padding: 10px 30px !important;
}
.DatePickerWrapper > div {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.DatePicker {
  position: relative;
  z-index: 10;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  width: 100%;
  transform: translateY(0);
  margin: auto;
  border-radius: 0.28571429rem !important;
  input {
    border: none !important;
    padding: 0 5px !important;
  }
  div {
    div:first-child {
      text-align: right;
    }
  }
}
.Header {
  margin-bottom: 30px;
}

.FormGroup {
  margin: 30px auto;
}

.SecondHeader {
  margin-top: 50px;
  margin-bottom: 30px;
}
