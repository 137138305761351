// Utility classes
.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;

  @include respond(tab-port) {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-top-medium {
  margin-top: 4rem !important;
}

.u-margin-top-tiny {
  margin-top: 2rem !important;
}

.u-margin-top-small {
  margin-top: 4rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-border-bottom-light {
  border-bottom: 1px solid $color-grey-light !important;
}

.u-padding-left-small {
  padding-left: 2rem !important;
}

.u-font-14 {
  font-size: 14px;
}

.u-float-right {
  float: right;
}

.u-color-white {
  color: $color-white !important;
}