@import 'src/sass/main.scss';

.Card {
  background: $color-primary-light;
  margin: 10px 0;
  height: 50px;
  padding: 13px 0;
  border-radius: $border-radius;
}

.CardIcon {
  color: lightgray;
  cursor: pointer;
}

.KeyIcon:hover {
  color: #ffd700;
}

.PenIcon:hover {
  color: rgb(10, 188, 201);
}

.CrossIcon:hover {
  color: rgb(201, 10, 10);
}
