@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translate(10rem, 0);
  }

  80% {
    transform: translate(-1rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}


@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translate(-10rem, 0);
  }

  80% {
    transform: translate(1rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}