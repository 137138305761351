.Container {
  margin: 0 0 0.5em 0;
}

.List {
  padding-top: 5px;
  list-style-type: none;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  justify-content: start;
  li {
    position: relative;
  }
  li:hover {
    cursor: pointer;
  }
}

.Tab {
  color: grey;
}

.ActiveTab {
  color: black;
  border-bottom: 1px solid black;
}

.Label {
  font-size: 13px;
  background: rgb(255, 136, 0);
  position: absolute;
  top: -5px;
  right: -10px;
  border-radius: 50%;
  color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
