.ModalWrapper > div {
  width: 80vw !important;
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.DropDownsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
  }
}
