@import 'src/sass/main.scss';

.CustomerDataDropdown {
  border: none;
  box-shadow: 0 0.1rem 0.2rem rgba($color-black, 0.2);

  &:hover {
    cursor: pointer !important;
  }
}

.Form {
  display: grid;
  grid-gap: 0px 10px;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
}

.DoubleSection {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}

.CustomerDataSection {
  border-bottom: 3px solid $color-grey-light;
  height: 250px;
}

.InputField {
  width: 100%;
  background-color: $color-primary;
  border: none !important;
  border-radius: $border-radius;
  margin: 0 !important;
}

.SwitchContainer {
  display: flex;
  padding: 0;
  margin: 5px auto auto;

  p {
    font-size: 16px;
    margin: auto 10px;

    svg {
      margin-right: 5px;
    }
  }
}

.BWTInput {
  position: relative;
  overflow: hidden;
  height: 30px;
  border-radius: 5px;
  span {
    top: 0;
    right: 0;
    bottom: 0;
    width: 40%;
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.FactoryBackground {
  background: #4bd1d6;
  color: white;
}

.RestaurantBackground {
  background: #f77347;
  color: white;
}
