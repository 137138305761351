@import "src/sass/main.scss";

.Wrapper {
  margin-bottom: 10px;
  flex:1;
}

.FormGroup {
  position: relative;
}

.Input {
  width: 100%;
  height: 34px;
  background-color: $color-primary;
  padding: 0 0 0 35px;
  border: none !important;
  border-radius: $border-radius;
  margin: 0 !important;

  &:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
}

.Icon {
  position: absolute;
  left: 10px;
  top: 8px;
}

.Error {
  box-shadow: inset 0 0 5px #ff8f8f;
}
