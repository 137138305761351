.TableContainer {
  position: relative;
  height: 100%;
}

.BWTTable {
  height: 100%;
}

.TableContent {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 50px;
  overflow: auto;
}

.TableHeader {
  display: flex;
  margin: 25px 0;
  justify-content: space-between;
}

.RefreshIcon {
  color: rgb(16, 152, 16);
  cursor: pointer;
  margin: 15px;
}

.Flex {
  display: flex;
  align-items: center;
}

.Row {
  display: flex;
  flex-flow: row;
  position: relative;
  border-top: dashed #d2d2d2;
  width: 100%;
}

.Time {
  position: absolute;
  z-index: 2;
  top: -15px;
  left: -25px;
}

.FactoryBackground {
  background: #4bd1d6 !important;
  color: white !important;
}

.RestaurantBackground {
  background: #f77347 !important;
  color: white !important;
}
