.CounterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.CounterNumber {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 5px;
  font-size: 20px;
  font-weight: bold;
  width: 55px;
  text-align: center;
}

.DeacrementButton {
  border: 1px solid #4cae4c;
  color: #4cae4c;
}

.IncreamentButton {
  border: 1px solid #d43f3a;
  color: #d43f3a;
}
