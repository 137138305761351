//Partial sass file
//Basic definitions of the project
*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

Button {
  font-size: .875rem !important;

  // Remove focus from buttons on click - bad for accessibility    
  &:focus {
    outline: none !important;
  }
}

a {

  // Remove focus from buttons on click - bad for accessibility    
  &:focus {
    outline: none !important;
  }
}

html {
  /* Root font size expressed in % takes into account people with disabiliteis and their custom settings in browser
    Default size of a browser is 16px */
  // Defines what 1rem is
  font-size: 100%; // 1rem = 10px
  scroll-behavior: smooth;

  // Order of media queries  must be correct - last one applies
  @include respond(tab-land) {
    font-size: 100%; //1rem = 9px
  }

  @include respond(tab-port) {
    font-size: 50%; //1rem = 8px
  }

  @include respond(phone) {
    font-size: 50%;
  }

  @include respond(big-desktop) {
    font-size: 100%; // 1rem = 12px
  }
}

body {
  box-sizing: border-box;
  padding: 0rem;
  padding-top: 0rem;

  @include respond(tab-port) {
    padding: 0;
  }
}