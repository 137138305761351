@import 'src/sass/main.scss';

.CustomerDataDropdown {
  border: none;
  box-shadow: 0 0.1rem 0.2rem rgba($color-black, 0.2);

  &:hover {
    cursor: pointer !important;
  }
}

.OrderStatus {
  text-align: center;
  padding: 0;
}

.OrderTimestampHeader {
  padding: 0;
}

.StatusItem {
  padding: 0.3rem 0;
  border-bottom: 1px dashed $color-grey-light;
  animation: slideDown 0.3s ease-in;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.StatusListContainer {
  height: 100%;
}

.OrderStatusHistorySection {
  padding: 0.5rem 0;
  height: 150px;
  overflow: hidden auto;
  border-bottom: 3px solid $color-grey-light;
}
