@import 'src/sass/main.scss';

.OrderSummaryItem {
  margin: 0.5rem 0;
  padding: 10px 0;
  background-color: $color-primary;
  border-radius: $border-radius;
  animation: slideDown 0.3s ease-in;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.RemoveItem {
  animation: slideRight 0.8s ease-in-out;
}

@keyframes slideRight {
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(100vw);
  }
}

.AlignCenter {
  text-align: center;
}

.DeleteCross {
  cursor: pointer;
}
