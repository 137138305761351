// Colors
// pallette: https://coolors.co/4157f3-f7d08a-23b5d3-415074-2c2c34

$color-primary: rgb(242, 242, 250);
$color-primary-dark: #a8a3b8;
$color-primary-light: #f3f4fa;
$color-black: rgb(15, 15, 15);
$color-white: #fff;
$color-accent-light: rgb(247, 241, 208);
$color-accent: rgb(226, 195, 17);
$color-dark-text: #2c2c34;
$color-medium-text: #707081;
$color-light-text: #dbdbeb;
$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-light: #e6e6e6;
$color-grey-light-1: #f7f7f7;
$color-secondary-dark: #e9b75b;
$color-secondary-light: rgb(250, 232, 200);
$color-tertiary-dark: #415074;
$color-tertiary-light: #707d9c;
$color-error: #690d0d;

//  FONT
$default-font-size: 1.6rem;

// Styling
$skew-value: 5deg;
$border-radius: 6px;
$phone-border-radius: 40px;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 6rem;
$gutter-vertical-mobile: 3rem;
$gutter-horizontal-small: 2rem;

// Progress bar mockup
$previous-weight: 305;
$goal-weight: 185;
$current-weight: 200;
$weight-progress: (
    ($previous-weight - $current-weight) / ($previous-weight - $goal-weight)
  ) * 100%;
