.Wrapper {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  width: 27vw;
  height: 43vh;
  text-align: center;
  padding: 1%;
  h2 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-size: 14px;
  }
}
.Border {
  width: 60%;
  margin: 0 auto;
  border-bottom: 1px solid #e0e0e0;
}
.IconWrapper {
  margin: 15px auto;
  width: 60px;
  height: 60px;
  background: #e0e0e0;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.ButtonContainer {
  margin-top: 1%;
  display: flex;
  flex-direction: column;

  h2 {
    color: black;
    padding: 12px 50px;
    border-radius: 8px;
    cursor: pointer;
  }
  h2:hover {
    color: white;
    background-color: #4283ca;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  }
}

@media (min-width: 1920px) {
  .Wrapper {
    padding: 30px 50px;
    height: 40vh;
    .ButtonContainer {
      margin-top: 10px;
    }
  }
}
