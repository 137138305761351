@import 'src/sass/main.scss';

.Menubar {
  background-color: $color-white;
  padding: 0.5rem;
  width: inherit;
}

.MenubarHeader {
  text-align: center;
  padding: 0.5rem 0;
}

.activeTag {
  background: red;
}

.Menubar {
  overflow-x: scroll;
  white-space: nowrap;
  text-align: center;

  a {
    padding: 10px;
    color: gray;
    border-bottom: 2px transparent solid;
  }

  a:hover {
    cursor: pointer;
    text-decoration: none;
  }
}
.Menubar::-webkit-scrollbar {
  display: none;
}

.activeCategory {
  border-bottom: 2px black solid !important;
}
