.ActionWrapper {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.CheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 10px;
}

.Table {
  font-size: 12px !important;
}

.DataItem {
  padding: 9px 30px !important;
}

.IdItem {
  padding: 9px 8px !important;
}
.ActionIcon:hover {
  color: rgb(10, 188, 201);
  cursor: pointer;
}

.NewRow {
  background: #699cff21;
}

.CanceledRow {
  background: #f1626038;
}

.ActionIcon {
  font-size: 17px !important;
}

.Switch {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.userIcon {
  min-width: 25px;
  min-height: 25px;
  margin: 0 5px;
  background-color: #cccccc;
  mask: url("../../../../img/user.svg") no-repeat center;
  -webkit-mask: url("../../../../img/user.svg") no-repeat center;
}

.icon {
  display: flex;
  align-items: center;
  > svg {
    width: 25px;
    height: 25px;
    margin: 0 5px;
  }
}

.active {
  color: #46b8da;
  > svg {
    fill: #46b8da;
  }
}

.DateWrapper {
  display: flex;
  margin: 40px 20px;
  justify-content: center;
  > p {
    margin-right: 10px;
  }
}

.CanceledRow {
  background: #f1626038;
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 98%;
  height: fit-content;
  padding-bottom: 20px;
}

.RefreshIcon {
  display: inline-block;
  color: #109810;
}

.RefreshIcon:hover {
  cursor: pointer;
}
h3 {
  margin: 10px 0 5px 0 !important;
}
.HeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    > h3 {
      margin: 5px 20px !important;
    }
  }
}
