.MenuBg {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #e5e5e5;
  padding: 0;
  margin: 0;
  z-index: -1;
}
.MenuWrapper {
  height: calc(100vh - 60px);
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
}
